import React, { useMemo } from "react";
import { useResize } from "../../../hooks/useResize";
import { ChartType } from "../../types";
import MemoizedBarChartH from "../../../components/Charts/BarChart/BarChartH";
import MemoizedLineChartH from "../../../components/Charts/LineChart/LineChartH";
import MemoizedPieChartH from "../../../components/Charts/PieChart/PieChartH";
import MemoizedScatterChartH from "../../../components/Charts/ScatterChart/ScatterChartH";
import { ChartProps } from "../ChartBuilder/ChartBuilder";

const ChartRenderer: React.FC<{ chartProps: ChartProps }> = ({ chartProps }) => {
  const { componentRef, width, height } = useResize();

  const renderChart = useMemo(() => {
    if (width === 0 || height === 0) return null;

    const props = {
      ...chartProps,
      chartConfig: {
        ...chartProps.chartConfig,
        columns: Object.keys(chartProps.selectedColumns),
      },
      width,
      height,
    };

    switch (chartProps.chartType) {
      case ChartType.BAR_CHART:
        return <MemoizedBarChartH {...props} />;
      case ChartType.LINE_CHART:
        return <MemoizedLineChartH {...props} />;
      case ChartType.PIE_CHART:
        return <MemoizedPieChartH {...props} />;
      case ChartType.SCATTER_CHART:
        return <MemoizedScatterChartH {...props} />;
      default:
        return null;
    }
  }, [chartProps.chartType, chartProps.docId, width, height]);

  return (
    <div ref={componentRef} className="chart-renderer flex flex-grow flex-col overflow-hidden">
      {renderChart}
    </div>
  );
};

export { ChartRenderer };
