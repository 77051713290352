import { HighchartsWithAccessibility, sharedChartOptions } from "../helpers";
import HighchartsReact from "highcharts-react-official";
import { memo } from "react";
import { CHART_DATA_LIMIT } from "../../../Constants/constants";
import { Loading } from "../../LoadingSpinner/Loading";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import ChartWarning from "../ChartWarning/ChartWarning";
import { ChartConfig } from "../chart.i";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
interface DataRow {
  [key: string]: any;
}

function extractLineData(data: DataRow[], chartConfig: ChartConfig) {
  const xAxisColumn = chartConfig.columns.find(
    (key) =>
      chartConfig.column_types[key] === "temporal" ||
      chartConfig.column_types[key] === "categorical"
  );
  const yAxisColumns = chartConfig.columns.filter(
    (key) => chartConfig.column_types[key] === "numerical"
  );

  const groupedData = data.reduce((acc, row) => {
    const xValue = row[xAxisColumn];
    if (!acc[xValue]) {
      acc[xValue] = {};
      yAxisColumns.forEach((yColumn) => {
        acc[xValue][yColumn] = 0;
      });
    }
    yAxisColumns.forEach((yColumn) => {
      acc[xValue][yColumn] += row[yColumn];
    });
    return acc;
  }, {});

  const categories = Object.keys(groupedData);
  const seriesData = yAxisColumns.map((yColumn) => ({
    name: yColumn,
    data: categories.map((category) => groupedData[category][yColumn]),
    type: "line" as const,
  }));

  return {
    categories,
    series: seriesData,
  };
}
const LineChartH = ({ data, height, width, chartConfig, loading, error, totalRows, title }) => {
  const { _ } = useLingui();
  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <Loading />;
  }
  if (error) {
    return <ChartErrorState />;
  }

  const { categories, series } = extractLineData(data, chartConfig);

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "line",
      height,
      width,
    },
    title: {
      text: chartConfig.chart_title || title || _(msg`Line Chart`),
    },
    xAxis: {
      categories: categories,
      title: {
        text: chartConfig.columns.find(
          (key) =>
            chartConfig.column_types[key] === "temporal" ||
            chartConfig.column_types[key] === "categorical"
        ),
      },
      crosshair: chartConfig.x_axis_crosshair ?? true,
    },
    yAxis: {
      title: {
        text: _(msg`Values`),
      },
    },
    series,
    credits: {
      enabled: false,
    },
    legend: {
      layout: chartConfig.legend_layout || "horizontal",
      align: "center",
      verticalAlign: chartConfig.legend_vertical_align || "bottom",
      floating: chartConfig.legend_floating ?? false,
    },
    tooltip: {
      enabled: chartConfig.tooltip ?? true,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: chartConfig.data_labels ?? false,
          formatter: function () {
            return this.y !== 0 ? this.y : null;
          },
        },
        enableMouseTracking: true,
      },
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} />
      {totalRows > CHART_DATA_LIMIT && <ChartWarning />}
    </div>
  );
};

const MemoizedLineChartH = memo(LineChartH, (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows &&
    prevProps.title === nextProps.title
  );
});

export default MemoizedLineChartH;
