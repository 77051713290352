// components/ActionsOptionsContainer.tsx
import React, { FC } from "react";
import { Menu, IconButton, Tooltip } from "@instructure/ui";
import {
  IconDownloadLine,
  IconTrashLine,
  IconPdfLine,
  IconMsExcelLine,
} from "@instructure/ui-icons";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import useBreakpoint from "../../../../../hooks/useBreakpoint";
import { useAuth } from "../../../../../context";
import { PINBOARD_UPGRADES } from "../../../../../Constants/features";
import { ActionsOptionsContainerProps } from "../types";
import { ActionsDropdown } from "./ActionsDropdown";

/**
 * Component for rendering action buttons or dropdown based on breakpoint.
 */
export const ActionsOptionsContainer: FC<ActionsOptionsContainerProps> = ({
  chartData,
  handleDownloadCSV,
  handleDownloadPDF,
  setDeleteChartModalOpen,
  variant,
}) => {
  const { _ } = useLingui();
  const { isChatDesktop } = useBreakpoint();
  const { featureFlags } = useAuth();

  return isChatDesktop ? (
    <div className="flex items-center gap-1">
      <Menu
        withArrow={false}
        placement="bottom start"
        offsetY={10}
        trigger={
          <Tooltip renderTip={_(msg`Export pin`)} placement="top">
            <IconButton
              withBackground={false}
              withBorder={false}
              screenReaderLabel={_(msg`export pin`)}
            >
              <IconDownloadLine />
            </IconButton>
          </Tooltip>
        }
      >
        {featureFlags.includes(PINBOARD_UPGRADES) && (
          <Menu.Item value="pdf" onClick={handleDownloadPDF}>
            <span className="flex items-center gap-2">
              <IconPdfLine />
              <Trans>Download data as PDF</Trans>
            </span>
          </Menu.Item>
        )}
        <Menu.Item value="csv" onClick={handleDownloadCSV}>
          <span className="flex items-center gap-2">
            <IconMsExcelLine />
            <Trans>Download data as CSV</Trans>
          </span>
        </Menu.Item>
      </Menu>
      {variant === "fullscreen" && (
        <>
          |
          <Tooltip renderTip={_(msg`Delete chart`)} placement="top">
            <IconButton
              withBackground={false}
              withBorder={false}
              screenReaderLabel={_(msg`Delete chart`)}
              onClick={() => setDeleteChartModalOpen(true)}
            >
              <IconTrashLine />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  ) : (
    <ActionsDropdown
      chartData={chartData}
      handleDownloadCSV={handleDownloadCSV}
      handleDownloadPDF={handleDownloadPDF}
      setDeleteChartModalOpen={setDeleteChartModalOpen}
      variant={variant}
    />
  );
};
