import { Pin } from "../../../context/pinboard/pinboard.i";
import { ChartType } from "../../types";

export const filterSearch = (search: string, data: Pin[]) => {
  if (!search) return data;

  const sanitizedSearch = search.toLowerCase().trim();

  return data.filter((pin) => pin.title.toLowerCase().includes(sanitizedSearch));
};


export const getImageSrc = (chartType: ChartType) => {
  switch (chartType) {
    case ChartType.BAR_CHART:
      return '/chart_images/bar_chart.png';
    case ChartType.PIE_CHART:
      return '/chart_images/pie_chart.png';
    case ChartType.LINE_CHART:
      return '/chart_images/line_chart.png';
    case ChartType.SCATTER_CHART:
      return '/chart_images/scatter_plot.png';
    default:
      return '/chart_images/data_table.png'; 
  }
};