// components/PinFullScreenView.tsx
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Button, CloseButton, Heading, Tooltip, TruncateText, Text } from "@instructure/ui";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Trans, msg, t } from "@lingui/macro";
import { withSentry } from "../../../../../helpers/wrapper";
import { TABLE } from "../../../../../Constants/constants";
import { useChatData } from "../../../../../context/chat";
import { usePinboard } from "../../../../../context/pinboard";
import { firebaseTimestampToLuxon } from "../../../../../helpers/time";
import { ChartType } from "../../../../types";
import { DataVisualizationTabs } from "../../../DataVisualizationTabs/DataVisualizationTabs";
import { useLingui } from "@lingui/react";
import { getRelativeDateAndTime } from "../helpers/time";
import { FullScreenPinProps, PageData } from "../types";
import { useAuth } from "../../../../../context";
import { PinViewContainer } from "./PinViewContainer";
import { ActionsOptionsContainer } from "./ActionsOptionsContainer";
import { VisualizationTypesEnum } from "../../../../../context/pinboard/pinboard.i";
import toast from "react-hot-toast";
import { handleDownloadCSVFunc, handleDownloadPDFFunc } from "../helpers/export";

/**
 * Component for displaying a full-screen view of a pinned chart.
 */
export const PinFullScreenView: FC<FullScreenPinProps> = ({
  open,
  setOpen,
  chart,
  setDeleteChartModalOpen,
}) => {
  const { baseURL } = useAuth();
  const { _ } = useLingui();
  const [pageData, setPageData] = useState<PageData>();
  const [pinChartType, setPinChartType] = useState<ChartType>(TABLE as ChartType);
  const [pinSelectedColumns, setPinSelectedColumns] = useState<{ [key: string]: any }>({});
  const [chatSummary, setChatSummary] = useState<string>(_(msg`Loading...`));
  const [isTitleTruncated, setIsTitleTruncated] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { currBoardId, updatePinVisualization } = usePinboard();
  const { fetchSummaryFromPin } = useChatData();

  const whiteboardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPinSelectedColumns(chart?.selectedColumns ?? {});
  }, [chart]);

  useEffect(() => {
    const fetchData = withSentry(async () => {
      const summary = await fetchSummaryFromPin(chart);
      setChatSummary(summary as unknown as string);
    });
    if (open && chart) {
      fetchData();
    }
  }, [open, chart]);

  useEffect(() => {
    return () => {
      setPinChartType(TABLE as ChartType);
      setPinSelectedColumns({});
    };
  }, [open]);

  const isDirty = useMemo(() => {
    const selectedColumnsSet = new Set(Object.keys(pinSelectedColumns));
    const chartSelectedColumnsSet = new Set(Object.keys(chart?.selectedColumns ?? {}));

    const columnsChanged =
      selectedColumnsSet.size !== chartSelectedColumnsSet.size ||
      ![...selectedColumnsSet].every((col) => chartSelectedColumnsSet.has(col));

    if (columnsChanged) {
      if (chart?.visualisation === TABLE && pinChartType === TABLE) return false;
      return true;
    }

    if ((chart?.visualisation as unknown as ChartType) !== pinChartType) return true;

    return false;
  }, [chart?.visualisation, pinChartType, pinSelectedColumns]);

  const updatePin = async () => {
    if (!chart) return;
    setUpdating(true);

    await updatePinVisualization(
      currBoardId,
      chart.id,
      pinSelectedColumns,
      pinChartType as unknown as VisualizationTypesEnum
    );

    setUpdating(false);
    handleClose();
  };

  const handleDownloadCSV = withSentry(async () => {
    await handleDownloadCSVFunc({ chart, currBoardId });
  });

  const handleDownloadPDF = async () => {
    toast.promise(
      withSentry(async () => {
        await handleDownloadPDFFunc({
          chart,
          baseURL,
          pageData,
          pinnedOn,
          lastUpdated,
          chatSummary,
          _,
          TABLE,
        });
      })(),
      {
        loading: t`Downloading data...`,
        success: t`Preparing PDF...`,
        error: (err) => {
          return err?.response?.data?.detail ?? t`Download data failed`;
        },
      }
    );
  };

  const [pinnedOn, lastUpdated] = useMemo(() => {
    const createdAtLuxon = firebaseTimestampToLuxon(chart?.created_at);
    const createdAtRelative = getRelativeDateAndTime(createdAtLuxon);

    const updatedAtLuxon = firebaseTimestampToLuxon(chart?.last_updated);
    const updatedAtRelative = getRelativeDateAndTime(updatedAtLuxon);

    return [createdAtRelative, updatedAtRelative];
  }, [chart?.created_at, chart?.last_updated]);

  const renderHeader = () => (
    <Heading>
      <TruncateText maxLines={3} onUpdate={(isTruncated) => setIsTitleTruncated(isTruncated)}>
        {chart?.title}
      </TruncateText>
    </Heading>
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PinViewContainer isOpen={open} handleClose={handleClose} size="auto">
      {/* Header */}
      <div id="full-screen-pin">
        <div className="full-screen-pin-remove">
          <CloseButton
            placement="end"
            offset="small"
            size="medium"
            screenReaderLabel={_(msg`Close`)}
            onClick={handleClose}
          />
        </div>
        {isTitleTruncated ? (
          <Tooltip
            renderTip={chart?.title}
            constrain="parent"
            mountNode={() => document.getElementById("modal-container")}
          >
            {renderHeader()}
          </Tooltip>
        ) : (
          renderHeader()
        )}
      </div>
      {/* Body */}
      <div>
        {/* Content */}
        <div className="flex flex-col justify-between md:flex-row">
          <div className="max-w-[690px]">
            <div className="mt-3">
              <ReactMarkdown children={chatSummary} rehypePlugins={[rehypeRaw]} />
            </div>
          </div>

          <div className="flex flex-col items-start justify-between gap-3 chat-desktop:items-end">
            <div className="flex flex-col items-start text-start chat-desktop:items-end chat-desktop:text-end">
              <span className="hidden chat-desktop:inline">
                <Text>
                  <Trans>Pinned On {pinnedOn}</Trans>
                </Text>
              </span>
              <Text>
                <Trans>Last Updated On {lastUpdated}</Trans>
              </Text>
            </div>

            <div className="full-screen-pin-remove">
              <ActionsOptionsContainer
                chartData={chart}
                handleDownloadCSV={handleDownloadCSV}
                handleDownloadPDF={handleDownloadPDF}
                setDeleteChartModalOpen={setDeleteChartModalOpen}
                variant="fullscreen"
              />
            </div>
          </div>
        </div>

        <div
          ref={whiteboardRef}
          style={{ minHeight: "45vh", height: "55vh" }}
          className="mt-5 flex flex-1 flex-col"
        >
          <DataVisualizationTabs
            result={chart}
            chartType={pinChartType}
            setChartType={setPinChartType}
            setPageData={setPageData}
            didCreateChart={(chartProps) => {
              setPinChartType(chartProps.chartType);
              setPinSelectedColumns(chartProps.selectedColumns);
            }}
          />
        </div>
      </div>
      {/* Footer */}
      <div className="full-screen-pin-remove flex items-center justify-end gap-2">
        <div className="mr-3">
          <Button color="primary" disabled={!isDirty || updating} onClick={updatePin}>
            <Trans>Save</Trans>
          </Button>
        </div>
        <Button onClick={handleClose}>
          <Trans>Close</Trans>
        </Button>
      </div>
    </PinViewContainer>
  );
};
