import {
  Flex,
  IconButton,
  IconCollectionSaveLine,
  IconFullScreenLine,
  IconMoreLine,
  IconRubricLine,
  Menu,
  Tooltip,
} from "@instructure/ui";

import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";
import { useChatData } from "../../../../../../../context/chat";
import { downloadCSV } from "../../../../../../../helpers/downloadCSV";
import { withSentry } from "../../../../../../../helpers/wrapper";
import { IconSQL } from "./IconSQL";
import { ParentDocTypeEnum } from "../../../../../../../api/retriever.i";

interface DropdownMenuProps {
  setSqlTrayOpen: (open: boolean) => void;
  disabled: boolean;
  invalidState: boolean;
  setFullScreenOpen: (open: boolean) => void;
}
export const DropdownMenu: FC<DropdownMenuProps> = ({
  setSqlTrayOpen,
  disabled,
  invalidState,
  setFullScreenOpen,
}) => {
  const { _ } = useLingui();
  const { currentResult: results, currentThread } = useChatData();

  const handleDownloadCSV = withSentry(async () => {
    await downloadCSV({
      doc_id: results?.id,
      parent_doc_id: currentThread,
      parent_doc_type: ParentDocTypeEnum.THREAD,
      filename: `${results?.title}.csv`,
    });
  });

  return (
    <Menu
      placement="bottom"
      trigger={
        <Tooltip renderTip={<Trans>More Options</Trans>} placement="top">
          <IconButton
            renderIcon={IconMoreLine}
            withBackground={false}
            withBorder={false}
            screenReaderLabel={_(msg`Open menu`)}
            disabled={disabled}
          />
        </Tooltip>
      }
      disabled={!results?.sql}
      mountNode={() => document.getElementById("main")}
    >
      <Menu.Item onSelect={() => setSqlTrayOpen(true)}>
        <Flex alignItems="center" gap="small">
          <IconSQL /> <Trans>Show SQL</Trans>
        </Flex>
      </Menu.Item>
      <Menu.Item onSelect={() => setFullScreenOpen(true)}>
        <Flex alignItems="center" gap="small">
          <IconFullScreenLine /> <Trans>Show Full Screen</Trans>
        </Flex>
      </Menu.Item>
      <Menu.Item
        disabled={invalidState}
        onSelect={() => handleDownloadCSV()}
        data-testid="download-csv"
      >
        <Flex alignItems="center" gap="small">
          <IconCollectionSaveLine /> <Trans>Download Data as CSV</Trans>
        </Flex>
      </Menu.Item>
    </Menu>
  );
};
