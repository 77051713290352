import { HighchartsWithAccessibility, sharedChartOptions } from "../helpers";
import HighchartsReact from "highcharts-react-official";
import { memo } from "react";
import { CHART_DATA_LIMIT } from "../../../Constants/constants";
import { Loading } from "../../LoadingSpinner/Loading";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import ChartWarning from "../ChartWarning/ChartWarning";
import { ChartConfig } from "../chart.i";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface DataRow {
  [key: string]: any;
}

function extractCategoricalData(data: DataRow[], chartConfig: ChartConfig) {
  const categoricalColumns = chartConfig?.columns?.filter(
    (key) => chartConfig.column_types[key] === "categorical"
  );
  const numericalColumns = chartConfig?.columns?.filter(
    (key) => chartConfig.column_types[key] === "numerical"
  );

  // Prepare series data based on the number of numerical columns
  const seriesData = numericalColumns?.map((numCol) => ({
    name: numCol,
    data: [],
    type: "bar" as const,
  }));

  const categoryData = {};
  data.forEach((row) => {
    const categoryKey = categoricalColumns?.map((column) => row[column]).join(" | ");
    if (!categoryData[categoryKey]) {
      categoryData[categoryKey] = {};
      numericalColumns.forEach((col) => {
        categoryData[categoryKey][col] = [];
      });
    }
    numericalColumns.forEach((col) => {
      categoryData[categoryKey][col].push(row[col]);
    });
  });

  const categories = Object.keys(categoryData);

  categories.forEach((category) => {
    numericalColumns.forEach((numCol, index) => {
      const aggregation =
        chartConfig.column_aggregations && chartConfig.column_aggregations[numCol] !== undefined
          ? chartConfig.column_aggregations[numCol]
          : null; // Or set a default value

      let value;
      switch (aggregation) {
        case "sum":
          value = categoryData[category][numCol].reduce((a, b) => a + b, 0);
          break;
        case "avg":
          value =
            categoryData[category][numCol].reduce((a, b) => a + b, 0) /
            categoryData[category][numCol].length;
          break;
        case "min":
          value = Math.min(...categoryData[category][numCol]);
          break;
        case "max":
          value = Math.max(...categoryData[category][numCol]);
          break;
        case "count":
          value = categoryData[category][numCol].length;
          break;
        default:
          value = categoryData[category][numCol].reduce((a, b) => a + b, 0);
      }
      value = typeof value === "number" ? Number(value.toFixed(2)) : 0;
      seriesData[index].data.push(value);
    });
  });

  return {
    categories,
    series: seriesData,
    categoricalColumns,
  };
}

const BarChartH = ({ data, height, width, chartConfig, loading, error, totalRows, title }) => {
  const { _ } = useLingui();
  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <Loading />; // or some other placeholder content
  }
  if (error) {
    return <ChartErrorState />;
  }

  const { categories, series, categoricalColumns } = extractCategoricalData(data, chartConfig);

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "bar",
      height,
      width,
    },
    title: {
      text: chartConfig.chart_title || title || _(msg`Bar Chart`),
    },
    xAxis: {
      categories: categories,
      title: {
        text: categoricalColumns.join(", "),
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: _(msg`Values`),
      },
      crosshair: chartConfig.y_axis_crosshair ?? true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: chartConfig.data_labels ?? false,
        },
      },
      series: {
        stacking: chartConfig.stack ? "normal" : undefined,
      },
    },
    series,
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: chartConfig.tooltip ?? true,
    },
    legend: {
      layout: chartConfig.legend_layout || "horizontal",
      align: chartConfig.legend_align || "center",
      verticalAlign: chartConfig.legend_vertical_align || "bottom",
      floating: chartConfig.legend_floating ?? false,
    },
  };

  return (
    <div style={{ position: "relative" }}>
      {totalRows > CHART_DATA_LIMIT && <ChartWarning />}

      <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} />
    </div>
  );
};

const MemoizedBarChartH = memo(BarChartH, (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows &&
    prevProps.title === nextProps.title
  );
});

export default MemoizedBarChartH;
