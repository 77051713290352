import { Button, View } from "@instructure/ui";
import { FC, useEffect, useRef, useState } from "react";

import { useChatData } from "../../../../../context/chat";
import { usePinboard } from "../../../../../context/pinboard";
import { Pin } from "../../../../../context/pinboard/pinboard.i";
import { useUI } from "../../../../../context/ui";
import { ViewEnum } from "../../../../../context/ui/ui.i";
import { withSentry } from "../../../../../helpers/wrapper";
import { Analytics } from "../../../../../services/analytics/Analytics";
import { DeleteModal } from "../../../UI/Modals/Delete";
import { ActionButtons } from "./ActionButtons";
import { ChartTitle } from "./ChartTitle";
import { useWindowSize } from "@uidotdev/usehooks";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { IconDragHandleLine } from "@instructure/ui-icons";
import { useAuth } from "../../../../../context";
import { PINBOARD_UPGRADES } from "../../../../../Constants/features";
import { getImageSrc } from "../../helpers";
import { ChartType } from "../../../../types";
import { PinFullScreenView } from "../../PinFullScreenView/components/PinFullScreenView";

export const PinboardChart: FC<{ chart: Pin }> = ({ chart }) => {
  const { _ } = useLingui();
  const pinboardRef = useRef(null);
  const { width: ViewWidth } = useWindowSize();

  const { updatePinTitle, unpinFromBoard, currBoardId } = usePinboard();
  const { navigateToChatFromPin } = useChatData();
  const { setView } = useUI();
  const { featureFlags } = useAuth();

  const [isFullScreen, setFullScreen] = useState(false);
  const [deleteChartModalOpen, setDeleteChartModalOpen] = useState(false);
  const [value, setValue] = useState(chart?.title);
  const [readOnly, setReadOnly] = useState(true);

  const handleViewChat = withSentry(async () => {
    await navigateToChatFromPin(chart?.id);

    setView(ViewEnum.CHAT_VIEW);
  });

  useEffect(() => {
    if (isFullScreen) {
      Analytics.track("Pinboard full screen");
    }
  }, [isFullScreen]);
  return (
    <>
      <div className="flex items-center justify-between border-b border-[#D7DADE] py-3 first:border-t">
        <div className="flex flex-1 items-center gap-6" ref={pinboardRef}>
          {featureFlags.includes(PINBOARD_UPGRADES) && (
            <Button
              renderIcon={IconDragHandleLine as unknown as React.ReactNode}
              withBackground={false}
              themeOverride={{ borderWidth: 0 }}
              onClick={() => console.log("drag")}
            />
          )}
          <img
            src={getImageSrc(chart?.visualisation as unknown as ChartType)} // Types need to be unified
            alt={`${chart?.visualisation} chart`}
          />
          <ChartTitle results={chart} setValue={setValue} value={value} readOnly={readOnly} />
        </div>

        <div className="flex min-w-fit flex-[1] items-center justify-end">
          <div className="flex">
            <Button size="small" margin="x-small" onClick={() => handleViewChat()}>
              <Trans>View Chat</Trans>
            </Button>
            <Button
              size="small"
              margin="x-small small x-small x-small"
              onClick={() => setFullScreen(true)}
            >
              <Trans>Full Screen</Trans>
            </Button>
          </div>

          <ActionButtons
            setDeleteChartModalOpen={setDeleteChartModalOpen}
            value={value}
            results={chart}
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            updateTitle={updatePinTitle}
            setValue={setValue}
          />
        </div>
      </div>

      {setDeleteChartModalOpen && (
        <DeleteModal
          open={deleteChartModalOpen}
          setOpen={setDeleteChartModalOpen}
          action={() => (unpinFromBoard as any)(chart?.id, currBoardId)}
          name={_(msg`Chart`)}
          text={_(msg`Are you sure you want to delete this chart?`)}
        />
      )}
      {isFullScreen && (
        <PinFullScreenView
          open={isFullScreen}
          setOpen={setFullScreen}
          chart={chart}
          setDeleteChartModalOpen={setDeleteChartModalOpen}
        />
      )}
    </>
  );
};
