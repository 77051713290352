import { FieldValue } from "@firebase/firestore";
import { TableColumns } from "../chart/chart.i";

export interface FirebaseTimestamp {
  seconds: number;
  nanoseconds: number;
}

export interface FirebaseDocument {
  id: string;
  can_edit: [];
  can_view: [];
  created_at: FirebaseTimestamp;
  last_updated: FirebaseTimestamp;
  timestamp: FirebaseTimestamp;
}

export enum VisualizationTypesEnum {
  TABLE = "TABLE",
  PIE_CHART = "PIE",
  BAR_CHART = "BAR",
  LINE_CHART = "LINE",
  SCATTER_CHART = "SCATTER",
  SAVANT_LINE = "SAVANT_LINE",
  COMBINED_CHART = "COMBINED_CHART",
  SQL_CHART = "SQL",
  EMPTY_ROWS = "EMPTY_ROWS",
  ERROR = "ERROR",
  CANCELED = "CANCELED",
}

export interface CreatePin {
  created_by: string;
  can_edit: [];
  can_view: [];
  pins: [];
  name: string;
  id: string;
  created_at: FieldValue;
  last_updated: FieldValue;
  color: string;
}

export type Headers = string[];
export type Rows = { [key: string]: any }[];
export type Data = { rows: Rows; totalRows: number; hyperlink_columns: any; data_types: object };
export type DataMap = {
  [pinId: string]: {
    rows: { [index: number]: any };
    pageInfo: { totalRowCount: number; headers: Headers; dataTypes: object };
  };
};
export interface Pin extends FirebaseDocument {
  data: { headers: Headers; rows: Rows; isDemo?: boolean };
  loading: boolean;
  query: string;
  sql: string;
  originalSql?: string;
  title: string;
  visualisation: VisualizationTypesEnum;
  visualisationArray: string[];
  selectedColumns: TableColumns;
  type: string;
  status: string;
  chartConfig: any;
  chart_config: any;
}

export interface Board {
  id: string;
  name: string;
  pins: string[]; // id[]
  created_by: string; // id
  created_at: FirebaseTimestamp;
  last_updated: FirebaseTimestamp;
  color?: string;
  can_edit: any[];
  can_view: any[];
}

export interface PinboardContextI {
  boards: any;
  currBoardId: string | null;
  loading: boolean;
  pinboardResults: Pin[];
  addNewBoard: (name: string) => Promise<string>;
  deleteBoard: (boardId: string) => Promise<void>;
  fetchPinboardResultsForBoard: () => Promise<void>;
  fetchNextPagePinboardResultsForBoard: () => Promise<void>;
  pinToBoard: (result: Pin, boardId: string) => Promise<void>;
  setBoards: (boards: Map<any, any>) => void;
  setCurrBoardId: (value: string | null) => void;
  setPinboardResults: (value: Pin[]) => void;
  updateBoardName: (boardId: string, name: string) => Promise<any>;
  updatePinTitle: (pinboardId: string, pinId: string, title: string) => Promise<any>;
  unpinFromBoard: (resultId: string, boardId: string) => Promise<any>;
  updatePinVisualization: (
    pinboardId: string,
    pinId: string,
    selectedColumns: TableColumns,
    visualisation: VisualizationTypesEnum
  ) => Promise<any>;
}
