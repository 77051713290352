import { Button, View } from "@instructure/ui";
import { FC, useEffect, useRef, useState } from "react";

import { useChatData } from "../../../../../context/chat";
import { usePinboard } from "../../../../../context/pinboard";
import { Pin } from "../../../../../context/pinboard/pinboard.i";
import { useUI } from "../../../../../context/ui";
import { ViewEnum } from "../../../../../context/ui/ui.i";
import { withSentry } from "../../../../../helpers/wrapper";
import { Analytics } from "../../../../../services/analytics/Analytics";
import { PinboardChartContent } from "../../../ChartContent/PinboardChartContent";
import { DeleteModal } from "../../../UI/Modals/Delete";
import { ActionButtons } from "./ActionButtons";
import { ChartTitle } from "./ChartTitle";
import { useWindowSize } from "@uidotdev/usehooks";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { PinFullScreenView } from "../../PinFullScreenView/components/PinFullScreenView";

export const PinboardChart: FC<{ chart: Pin }> = ({ chart }) => {
  const { _ } = useLingui();
  const pinboardRef = useRef(null);
  const { width: ViewWidth } = useWindowSize();

  const { updatePinTitle, unpinFromBoard, currBoardId } = usePinboard();
  const { navigateToChatFromPin } = useChatData();
  const { setView } = useUI();

  const [isFullScreen, setFullScreen] = useState(false);
  const [deleteChartModalOpen, setDeleteChartModalOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [value, setValue] = useState(chart?.title);
  const [readOnly, setReadOnly] = useState(true);

  const handleViewChat = withSentry(async () => {
    await navigateToChatFromPin(chart?.id);

    setView(ViewEnum.CHAT_VIEW);
  });

  useEffect(() => {
    if (isFullScreen) {
      Analytics.track("Pinboard full screen");
    }
  }, [isFullScreen]);

  useEffect(() => {
    if (pinboardRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setHeight(entry.contentRect.height - 10);
          setWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(pinboardRef.current);

      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }
  }, [pinboardRef.current]);

  return (
    <View
      as="div"
      background="primary"
      height="43vh"
      minHeight="350px"
      borderWidth="small"
      borderRadius="large"
      shadow="resting"
      width={ViewWidth - 30}
    >
      <div className="flex h-full flex-col">
        <div className="flex items-center justify-between">
          <div className="ml-3 max-w-[60%] flex-1 overflow-hidden">
            <ChartTitle results={chart} setValue={setValue} value={value} readOnly={readOnly} />
          </div>
          <div>
            <ActionButtons
              setDeleteChartModalOpen={setDeleteChartModalOpen}
              value={value}
              results={chart}
              readOnly={readOnly}
              setReadOnly={setReadOnly}
              updateTitle={updatePinTitle}
              setValue={setValue}
            />
          </div>
        </div>
        <div className="mx-3 flex flex-1" ref={pinboardRef}>
          <PinboardChartContent height={height ?? 100} width={width ?? 100} pinResult={chart} />
        </div>
        <div className="border-b-solid mt-1 border-b border-b-[#C7CDD1]" />
        <div className="flex justify-end">
          <Button size="small" margin="x-small" onClick={() => handleViewChat()}>
            <Trans>View Chat</Trans>
          </Button>
          <Button
            size="small"
            margin="x-small small x-small x-small"
            onClick={() => setFullScreen(true)}
          >
            <Trans>Full Screen</Trans>
          </Button>
        </div>
      </div>

      {setDeleteChartModalOpen && (
        <DeleteModal
          open={deleteChartModalOpen}
          setOpen={setDeleteChartModalOpen}
          action={() => (unpinFromBoard as any)(chart?.id, currBoardId)}
          name={_(msg`Chart`)}
          text={_(msg`Are you sure you want to delete this chart?`)}
        />
      )}
      {isFullScreen && (
        <PinFullScreenView
          open={isFullScreen}
          setOpen={setFullScreen}
          chart={chart}
          setDeleteChartModalOpen={setDeleteChartModalOpen}
        />
      )}
    </View>
  );
};
