// components/downloadHandlers.ts
import { downloadCSV, sanitizeFilename } from "../../../../../helpers/downloadCSV";
import { ParentDocTypeEnum } from "../../../../../api/retriever.i";
import jsPDF from "jspdf";
import { marked } from "marked";
import autoTable from "jspdf-autotable";
import { toPng } from "html-to-image";
import { Analytics } from "../../../../../services/analytics/Analytics";
import { msg } from "@lingui/macro";

interface HandleDownloadCSVParams {
  chart: any;
  currBoardId: string;
}

interface HandleDownloadPDFParams {
  chart: any;
  baseURL: string;
  pageData: any;
  pinnedOn: string;
  lastUpdated: string;
  chatSummary: string;
  _: any;
  TABLE: any;
}


export const handleDownloadCSVFunc = async ({ chart, currBoardId }: HandleDownloadCSVParams) => {
  await downloadCSV({
    doc_id: chart?.id,
    parent_doc_id: currBoardId,
    parent_doc_type: ParentDocTypeEnum.PINBOARD,
    filename: `${chart?.title}.csv`,
  });
};

export const handleDownloadPDFFunc = async ({
  chart,
  baseURL,
  pageData,
  pinnedOn,
  lastUpdated,
  chatSummary,
  _,
  TABLE,
}: HandleDownloadPDFParams) => {
  const contentWidth = 190;
  try {
    const sanitizedFilename = sanitizeFilename(`${chart?.title}.pdf`);
    const doc = new jsPDF();

    // Title
    const splitTitle = doc.splitTextToSize(chart?.title, contentWidth);
    doc.setFontSize(16);
    doc.text(splitTitle, 10, 10);
    doc.setFontSize(10);
    const titleSeparatorStartY = splitTitle.length * 10 + 5;
    doc.line(10, titleSeparatorStartY, 200, titleSeparatorStartY);

    doc.setFontSize(10);
    doc.text(_(msg`Pinned On: ${pinnedOn}`), 10, titleSeparatorStartY + 5);
    doc.text(_(msg`Last Updated On: ${lastUpdated}`), 10, titleSeparatorStartY + 10);

    const htmlContent = await marked(chatSummary);

    const tableHeaders = pageData?.pageInfo.headers;

    await doc.html(htmlContent, {
      callback: async (doc) => {
        if (pageData) {
          doc.addPage();
          autoTable(doc, {
            head: [tableHeaders],
            body: pageData?.rows.map((row: any) => tableHeaders.map((header: any) => row[header])),
            didDrawCell: (data: any) => {
              const x = data.cell.x;
              const y = data.cell.y + 5;

              if (data.cell.section === "body") {
                if (
                  pageData?.pageInfo?.hyperlinkColumns?.courses?.includes(
                    tableHeaders[data.column.index]
                  )
                ) {
                  //@ts-ignore
                  doc.setFillColor(data.cell.styles.fillColor);
                  doc.rect(x, y - 5, data.cell.width, data.cell.height, "F");
                  doc.setTextColor("blue");
                  doc.textWithLink(data.cell.raw.toString(), x, y, {
                    url: `${baseURL}/courses/${data.cell.raw}`,
                  });
                } else if (
                  pageData?.pageInfo?.hyperlinkColumns?.gradebook?.includes(
                    tableHeaders[data.column.index]
                  )
                ) {
                  //@ts-ignore
                  doc.setFillColor(data.cell.styles.fillColor);
                  doc.rect(x, y - 5, data.cell.width, data.cell.height, "F");
                  doc.setTextColor("blue");
                  doc.textWithLink(data.cell.raw.toString(), x, y, {
                    url: `${baseURL}/courses/${data.row.raw[0]}/gradebook`,
                  });
                }
              }
            },
          });

          const posY = (doc as any).lastAutoTable.finalY;
          doc.setFontSize(10);
          doc.text(
            _(msg`${pageData?.rows.length} rows of ${pageData?.pageInfo?.totalRowCount}`),
            contentWidth,
            posY + 10,
            { align: "right" }
          );
        }

        if (chart.visualisation !== TABLE) {
          const dataContentDom = document.querySelector(
            "#full-screen-pin .chart-renderer"
          ) as HTMLElement;
          if (dataContentDom) {
            const canvasImage = await toPng(dataContentDom);

            // Get dimensions directly from the DOM element
            const originalWidth = dataContentDom.offsetWidth;
            const originalHeight = dataContentDom.offsetHeight;

            // Calculate aspect ratio
            const aspectRatio = originalWidth / originalHeight;

            // Define maximum width and height for the PDF
            const maxWidth = 190;
            const maxHeight = 160;

            // Calculate dimensions that fit within maxWidth and maxHeight while maintaining aspect ratio
            let width = maxWidth;
            let height = width / aspectRatio;

            if (height > maxHeight) {
              height = maxHeight;
              width = height * aspectRatio;
            }

            doc.addPage();
            doc.addImage(canvasImage, "PNG", 10, 15, width, height);
          }
        }

        doc.save(sanitizedFilename);
      },
      x: 10,
      y: titleSeparatorStartY + 20,
      width: contentWidth,
      windowWidth: 700,
      autoPaging: "text",
    });
  } catch (error) {
    console.log(error);
    throw error;
  } finally {
    Analytics.track("Download PDF");
  }
};
