// components/ActionsDropdown.tsx
import React, { FC, useState } from "react";
import { Menu, Button, Flex, Text } from "@instructure/ui";
import {
  IconArrowOpenDownLine,
  IconPdfLine,
  IconMsExcelLine,
  IconTrashLine,
} from "@instructure/ui-icons";
import { Trans } from "@lingui/macro";
import { SQLTray } from "../../../ChatTab/ResultArea/Whiteboard/SQLTray/SQLTray";
import { IconSQL } from "../../../ChatTab/ResultArea/Whiteboard/WhiteboardHeader/DropdownMenu/IconSQL";
import { ActionsOptionsContainerProps } from "../types";

/**
 * Dropdown menu for actions in mobile view.
 */
export const ActionsDropdown: FC<ActionsOptionsContainerProps> = ({
  chartData,
  handleDownloadCSV,
  handleDownloadPDF,
  setDeleteChartModalOpen,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [sqlTrayOpen, setSqlTrayOpen] = useState(false);

  return (
    <div className="w-full">
      <Menu
        show={showMenu}
        onToggle={(show) => setShowMenu(show)}
        placement="bottom stretch"
        withArrow={false}
        offsetY={-38}
        trigger={
          <Button display="block" color="primary-inverse">
            <div className="flex items-center justify-between">
              <Trans>Actions</Trans>
              <IconArrowOpenDownLine />
            </div>
          </Button>
        }
        shouldHideOnSelect={false}
        themeOverride={{ maxWidth: "auto" }}
      >
        <Menu.Item
          onSelect={() => {
            setSqlTrayOpen(true);
            setShowMenu(false);
          }}
        >
          <Flex alignItems="center" gap="small">
            <IconSQL />
            <Trans>Show SQL</Trans>
          </Flex>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            handleDownloadPDF();
            setShowMenu(false);
          }}
        >
          <div className="flex items-center gap-3">
            <IconPdfLine />
            <Text>
              <Trans>Download as PDF</Trans>
            </Text>
          </div>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            handleDownloadCSV();
            setShowMenu(false);
          }}
        >
          <div className="flex items-center gap-3">
            <IconMsExcelLine />
            <Text>
              <Trans>Download as CSV</Trans>
            </Text>
          </div>
        </Menu.Item>
        <Menu.Separator />
        <Menu.Item onClick={() => setDeleteChartModalOpen(true)}>
          <div className="flex items-center gap-3">
            <IconTrashLine />
            <Text>
              <Trans>Delete from pinboard</Trans>
            </Text>
          </div>
        </Menu.Item>
      </Menu>
      <SQLTray results={chartData} sqlTrayOpen={sqlTrayOpen} setSqlTrayOpen={setSqlTrayOpen} />
    </div>
  );
};
